<template>
  <div>
    <header-slot :clients-search="true" :global-search="true">
      <template v-if="changeNavAction == true" #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <!-- Title Search Global -->
          <!-- Input Search Global -->
          <b-col cols="12" class="pl-0">
            <b-input-group class="mr-1">
              <b-input-group-prepend>
                <b-button variant="outline-info"> GLOBAL SEARCH </b-button>
              </b-input-group-prepend>
              <b-form-input
                v-model="campo1"
                placeholder="Search by Name, Account or the last 4 digits of Phone number"
                @keyup.enter="search"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="search">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <!-- Nav Clients and Shared -->
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: 'debt-s-clients' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        @click="changeNav(true)"
        >Clients</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'debt-s-shared' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        @click="changeNav(false)"
        >Shared</b-nav-item
      >
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
    <!-- Modal Search Global -->
    <modal-global-search
      v-if="modalGlobalSearch"
      :modal-global-search="modalGlobalSearch"
      :clients-global="clientsGlobal"
      @closeModal="closeGlobalSearch"
    />
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import { validateModalActions } from "@/helpers/open-modal-alerts";

// Import Service
import ClientService from "./services/clients.services";
// Import Components
import ModalGlobalSearch from "./modals/ModalGlobalSearch.vue";

export default {
  components: {
    ModalGlobalSearch,
    ModalSearchPayment,
  },
  data() {
    return {
      campo1: "",
      modalGlobalSearch: false,
      clientsGlobal: [],
      changeNavAction: true,
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },
  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
  },
  async created() {
    await Promise.all([this.moduleId()]);
    await validateModalActions();
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    changeNav(value) {
      this.changeNavAction = value;
    },
    async search() {
      try {
        this.addPreloader();
        const params = {
          text: this.campo1,
          from: null,
          to: null,
          program: 3,
          orderby: 5,
          order: "desc",
          status: null,
          advisor: null,
          type: null,
          day: null,
          rol_id: 1,
          showall: true,
        };
        const data = await ClientService.search(params);
        this.clientsGlobal = data.data.data;
        this.modalGlobalSearch = true;
        this.removePreloader();
      } catch (error) {
        console.error(error);
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
      }
    },
    closeGlobalSearch() {
      this.modalGlobalSearch = false;
    },
  },
};
</script>
