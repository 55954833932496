<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <div class="d-flex justify-content-end">
          <court-info-action />

          <!-- Share -->
          <b-button
            v-if="currentUser.role_id == 1 || currentUser.role_id == 2"
            :disabled="isResponsibleAccount"
            class="ml-1"
            @click="openModalShare"
          >
            <feather-icon icon="Share2Icon" />
            Share
          </b-button>
          <!-- Other Programs -->

          <b-button
            :disabled="client != null ? client.count_program == 0 : ''"
            class="ml-1"
            :style="
              client.count_program == 0
                ? 'background: #82868b !important; border-color: #82868b !important; background-color: #82868b !important;'
                : ''
            "
            @click="openModalProgramList"
          >
            <feather-icon icon="MonitorIcon" />
            Others Programs
          </b-button>

          <!-- Send CR -->
          <b-dropdown
            class="ml-1"
            right
            text="Send CR"
            :disabled="isResponsibleAccount"
          >
            <template #button-content>
              <feather-icon icon="FileTextIcon" />
              Request CR
              <b-badge
                v-if="countNcrForUpdate > 0"
                v-b-tooltip.hover
                title="The specialist requests a new NCR because it has already expired"
                variant="info"
                >{{ countNcrForUpdate }}</b-badge
              >
            </template>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              Automatic
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              Manual
            </b-dropdown-item>
          </b-dropdown>
          <!-- Send -->
          <b-dropdown class="ml-1" right :disabled="isResponsibleAccount">
            <template #button-content>
              <feather-icon icon="SendIcon" />
              Send
            </template>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="openModalSendSms"> Send SMS</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistorySms"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="openModalSendEmail"> Send Email</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryEmail"
                />
              </div>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="d-flex justify-content-between">
                <span @click="openModalStatusReport"> Status Report</span>
                <feather-icon
                  icon="MoreVerticalIcon"
                  @click="openModalHistoryStatement"
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </header-slot>

    <b-card no-body class="m-0">
      <div class="m-3">
        <b-row>
          <b-col>
            <b-row>
              <b-col lg="auto">
                <b-skeleton-img
                  v-if="!validateImg"
                  width="80px"
                  height="80px"
                />
                <b-img
                  v-if="validateImg"
                  :src="getProgramLogo(client.program_name)"
                  thumbnail
                  fluid
                  style="max-width: 80px; max-height: 80px"
                />
              </b-col>
              <b-col lg="auto">
                <b-skeleton-wrapper :loading="!validateAccount">
                  <template #loading>
                    <b-card no-body>
                      <b-skeleton width="85%" style="min-width: 124px" />
                      <b-skeleton width="55%" style="min-width: 95px" />
                      <b-skeleton width="70%" style="min-width: 110px" />
                    </b-card>
                  </template>
                </b-skeleton-wrapper>
                <b-form-group style="margin-bottom: 5px !important">
                  <template v-if="validateAccount">
                    <span
                      style="font-weight: bolder; font-size: 17.5px"
                      @click="isResponsibleAccount ? '' : openEditLead()"
                      :class="isResponsibleAccount ? '' : 'cursor-pointer'"
                      >{{ client.client_name }}
                    </span>
                    <span
                      v-if="
                        $store.getters[
                          'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                        ].is_responsible
                      "
                      class="text-primary"
                    >
                      <tabler-icon
                        :icon="
                          $store.getters[
                            'NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'
                          ].is_responsible === currentUser.user_id
                            ? 'StarIcon'
                            : 'LockIcon'
                        "
                        size="15"
                        style="margin-left: 5px"
                      />
                      <strong>CEO's client</strong>
                    </span>
                    <br />
                  </template>
                </b-form-group>
                <b-form-group>
                  <template v-if="validateAccount">
                    <span style="font-size: 14.5px"
                      ><span
                        style="
                          font-weight: bolder;
                          font-size: 14px;
                          margin-right: 5px;
                        "
                        >Account:</span
                      >
                      {{ client.account }}</span
                    >
                    <br />

                    <StatusClientAccount />
                  </template>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col>
            <div class="indicators">
              <!-- Amount Saved -->
              <div id="AmountSaved" style="margin-bottom: 10px">
                <div class="d-flex justify-content-end align-items-center">
                  <div
                    :class="'av-balance-border-blue'"
                    class="d-flex float-right mr-2"
                  >
                    <div :class="'av-balance-text-blue'" class="text-center">
                      Amount Saved
                    </div>
                    <h2
                      :class="'av-balance-content-blue'"
                      style="white-space: nowrap"
                    >
                      $
                      {{ amountSaved ? formatPrice(amountSaved) : 0.0 }}
                    </h2>
                  </div>
                </div>
              </div>
              <b-tooltip
                target="AmountSaved"
                triggers="hover"
                placement="bottom"
              >
                <ul style="list-style: none; margin-left: 0; padding-left: 0">
                  <li style="display: flex; margin-bottom: 0.5rem">
                    <span style="margin-left: 0.5rem"
                      >Total savings after paying the bank and subtracting AMG
                      expenses</span
                    >
                  </li>
                </ul>
              </b-tooltip>
              <!-- Maximum Balance -->
              <div id="MaximumBalance" style="margin-bottom: 10px">
                <div class="d-flex justify-content-end">
                  <div
                    :class="'av-balance-border-blue'"
                    class="d-flex float-right mr-2"
                  >
                    <div :class="'av-balance-text-blue'" class="text-center">
                      Maximum Balance
                    </div>
                    <h2
                      :class="'av-balance-content-blue'"
                      style="white-space: nowrap"
                    >
                      $
                      {{
                        maximum_balance
                          ? formatPrice(maximum_balance * 0.8)
                          : 0.0
                      }}
                    </h2>
                  </div>
                </div>
              </div>
              <b-tooltip
                target="MaximumBalance"
                triggers="hover"
                placement="bottom"
              >
                <ul style="list-style: none; margin-left: 0; padding-left: 0">
                  <li style="display: flex; margin-bottom: 0.5rem">
                    <span style="margin-left: 0.5rem"
                      >Maximum amount to be paid by the customer o
                      Remaining</span
                    >
                  </li>
                </ul>
              </b-tooltip>
              <!-- Available Balance -->
              <div style="margin-bottom: 10px">
                <div class="d-flex justify-content-end">
                  <div
                    :class="
                      client.st_balance == 1
                        ? 'av-balance-border-blue'
                        : 'av-balance-border-red'
                    "
                    class="d-flex float-right mr-2"
                  >
                    <div
                      :class="
                        client.st_balance == 1
                          ? 'av-balance-text-blue'
                          : 'av-balance-text-red'
                      "
                      class="text-center"
                    >
                      Available Balance
                    </div>
                    <h2
                      v-if="client.balance != null"
                      :class="
                        client.st_balance == 1
                          ? 'av-balance-content-blue'
                          : 'av-balance-content-red'
                      "
                      style="white-space: nowrap"
                    >
                      $ {{ client.balance ? formatPrice(client.balance) : 0.0 }}
                    </h2>
                    <h2
                      v-if="client.balance == null"
                      :class="
                        client.st_balance == 1
                          ? 'av-balance-content-blue'
                          : 'av-balance-content-red'
                      "
                      style="white-space: nowrap"
                    >
                      $ 0
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-nav pills class="ml-3 mb-0">
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Personal Information'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'personal-information-general' }
              : { name: 'personal-information' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Personal Information
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Notes'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'task-notes-general' }
              : { name: 'task-notes' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          exact
          >Notes
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Payments'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'pay-general' }
              : { name: 'pay' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Pay</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Files'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'client-files-general' }
              : { name: 'client-files' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Files
          <span
            v-if="countfileclient > 0"
            class="position-absolute small top-0 start-100 translate-middle badge rounded-pill bg-danger"
            style="
              font-size: 90% !important;
              margin-left: 68px;
              margin-top: -2px;
              padding: %;
            "
          >
            {{ countfileclient }}</span
          >
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Access Credentials'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'acr-general' }
              : { name: 'acr' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACr</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Dispute'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'dis-general' }
              : { name: 'dis' }
          "
          exact
          :active="isActiveDispute"
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Credit
          <b-badge v-if="creditCounter > 0" pill variant="danger" class="ml-1">
            {{ creditCounter }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Accounts'"
          exact
          :to="
            $route.matched[0].meta.general
              ? { name: 'acc-general' }
              : { name: 'acc' }
          "
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >ACC
        </b-nav-item>
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Report'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'ds-questionnaire-general' }
              : { name: 'ds-questionnaire' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Questionnaire</b-nav-item
        >
        <b-nav-item
          v-b-tooltip.hover.bottomright="'Call Log'"
          :active="isCallLog"
          :to="
            $route.matched[0].meta.general
              ? { name: 'ds-call-log-general' }
              : { name: 'ds-call-log' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Call Log</b-nav-item
        >
        <b-nav-item
          v-if="validationArrRoles"
          v-b-tooltip.hover.bottomright="'Customer Tickets'"
          :to="
            $route.matched[0].meta.general
              ? { name: 'ds-client-tickets-general' }
              : { name: 'ds-client-tickets' }
          "
          exact
          exact-active-class="active border-radius-tabs"
          :link-classes="['h-full px-3', bgTabsNavs]"
          >Customer Tickets
          <span
            v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 0"
            class="ml-1"
          >
            <feather-icon
              icon
              :badge="
                G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open > 99
                  ? '99+'
                  : G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD.v_total_open
              "
              badge-classes="badge-important"
            />
          </span>
        </b-nav-item>
      </b-nav>
    </b-card>
    <hr class="border-primary border-3 px-0 mt-0" />

    <!-- <b-card class="border-top-primary border-3 border-table-radius px-0"> -->
    <router-view v-if="showViews" />
    <!-- </b-card> -->

    <!-- Header Modals -->
    <lead-update
      v-if="isAddUpdateUserSidebarActive"
      :is-add-new-user-sidebar-active.sync="isAddUpdateUserSidebarActive"
      :lead="S_LEAD_EDIT"
      :type-edit="typeEdit"
      @refresh="refresh"
    />
    <modal-share
      v-if="modalShare"
      :modal-share="modalShare"
      @closeModalShare="closeModalShare"
    />
    <modal-program-list
      v-if="modalProgramList"
      :modal-program-list="modalProgramList"
      @closeModalProgramList="closeModalProgramList"
    />
    <!-- Send CR -->
    <!-- <modal-create-ncr
      v-if="modalCreateNcr"
      :modal-create-ncr="modalCreateNcr"
      :typencr="1"
      @closeModalCreateNcr="closeModalCreateNcr"
    />

    <modal-create-ncr-realtor
      v-if="modalCreateNcrRealtor"
      :typencr="1"
      @closeModalCreateNcrRealtor="closeModalCreateNcrRealtor"
    /> -->

    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncr-type="selectedNcr"
      @close="closeModalCreateNcr"
      @reload="refresh"
    />

    <!-- Send -->
    <modal-send-sms
      v-if="modalSendSms"
      :modal-send-sms="modalSendSms"
      :name-leads="{ id: client.id, name: client.client_name }"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-send-email
      v-if="modalSendEmail"
      :modal-send-email="modalSendEmail"
      :name-leads="[{ id: client.id, name: client.client_name }]"
      :typesms="0"
      @closeModal="closeSendSms"
    />

    <modal-status-report
      v-if="modalStatusReport"
      :modal-status-report="modalStatusReport"
      @closeModalStatus="closeModalStatus"
    />

    <modal-history
      v-if="modalHistoryOn"
      :modal-history="modalHistoryOn"
      :name-program="client.program_name"
      :name-client="client.client_name"
      @closeModalHistory="closeModalHistory"
    />
    <modal-history-sms
      v-if="activeModalHistorySms"
      :active="activeModalHistorySms"
      @close="closeModalHistorySms"
    />
    <modal-history-email
      v-if="activeModalHistoryEmail"
      :active="activeModalHistoryEmail"
      @close="closeModalHistoryEmail"
    />
    <modal-history-statement
      v-if="activeModalHistoryStatement"
      :active="activeModalHistoryStatement"
      @close="closeModalHistoryStatement"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import LeadUpdate from "@/views/crm/views/Lead/lead-module/save/LeadUpdate.vue";
import ModalHistory from "@/views/commons/components/clients/dashboard/information-client/modals/ModalHistory.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";
import ModalHistorySms from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistorySms.vue";
import ModalHistoryEmail from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryEmail.vue";
import ModalHistoryStatement from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryStatement.vue";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import ClientInformation from "./information-client/ClientInformation.vue";
// Import Modals
import ModalShare from "./information-client/modals/ModalShare.vue";
import ModalProgramList from "./information-client/modals/ModalProgramList.vue";
// import ModalCreateNcr from "./information-client/modals/ModalCreateNcr.vue";
// import ModalCreateNcrRealtor from "./information-client/modals/ModalCreateNcrRealtor.vue";

import ModalSendSms from "../modals/ModalSendSms.vue";
import ModalSendEmail from "../modals/ModalSendEmail.vue";
import ModalStatusReport from "./information-client/modals/ModalStatusReport.vue";
import StatusClientAccount from "@/views/administration/views/clients/components/StatusClientAccount.vue";

export default {
  components: {
    ModalHistoryStatement,
    ModalHistoryEmail,
    ModalHistorySms,
    ClientInformation,
    ModalShare,
    ModalProgramList,
    // ModalCreateNcr,
    // ModalCreateNcrRealtor,
    ModalSendSms,
    ModalSendEmail,
    ModalStatusReport,
    LeadUpdate,
    ModalHistory,
    ModalCreateNcrRequest,
    CourtInfoAction,
    StatusClientAccount,
  },

  data() {
    return {
      showViews: false,
      activeModalHistoryStatement: false,
      activeModalHistoryEmail: false,
      activeModalHistorySms: false,
      validateAccount: false,
      validateImg: false,
      typeEdit: "lead",
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      modalShare: false,
      modalProgramList: false,
      modalCreateNcr: false,
      modalCreateNcrRealtor: false,
      modalSendSms: false,
      modalSendEmail: false,
      modalStatusReport: false,
      isAddUpdateUserSidebarActive: false,
      editSidebar: "first",
      modalHistoryOn: false,
      statusstate: false,
      modalHistoryClaims: false,
      selectedNcr: null,
      showModalSendReport: false,
      countNcrForUpdate: null,
    };
  },
  computed: {
    isPersonalInformation() {
      return this.$route.fullPath.includes("personal-information");
    },
    isActiveDispute() {
      if (this.$route.matched[0].meta.general) {
        return this.$route.matched?.[1]?.name === "dis-general";
      }
      return this.$route.matched?.[2]?.name === "dis";
    },
    isService() {
      return this.$route.matched?.[2]?.name == "services-client-boost-credit";
    },
    isCallLog() {
      if (this.$route.matched[2]) {
        const { callConversationRoute, callNoConversationRoute } =
          this.$route.matched[2].meta;
        return [callConversationRoute, callNoConversationRoute].includes(
          this.$route.name
        );
      }
      return false;
    },
    validationArrRoles() {
      if (
        this.currentUser.arrRoles.find(
          (rol) =>
            (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) &&
            rol.module_id !== 24 &&
            rol.user_id !== 38
        )
      ) {
        return true;
      }
      return false;
    },
    statusDark() {
      return this.skin == "dark" ? "dark" : "";
    },
    ...mapState({
      S_LEAD_EDIT: (state) => state.CrmLeadStore.S_LEAD_EDIT,
      // amount saved
      S_AMOUNTSAVED: (state) => state.DebtSolutionClients.S_AMOUNTSAVED,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      countdisputeclient: "DebtSolutionClients/G_COUNT_DISPUTE",
      countfileclient: "DebtSolutionClients/G_COUNT_FILES",
      amountSaved: "DebtSolutionClients/G_AMOUNTSAVED",
      maximum_balance: "DebtSolutionClients/G_MAXIMUM_BALANCE",
      G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
      G_COUNTER_ALL_SERVICES: "clients-store/G_COUNTER_ALL_SERVICES",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
      G_COUNT_PENDING_WP_CLIENT: "clients-store/G_COUNT_PENDING_WP_CLIENT",
      updates: "ReportStore/G_UPDATES_DISPUTE",
      missingFiles: "ReportStore/G_MISSING_FILES",
    }),
    creditCounter() {
      return (
        this.cPendingNcrByClient +
        this.G_COUNT_PENDING_WP_CLIENT +
        this.updates +
        this.missingFiles +
        this.G_COUNTER_ALL_SERVICES
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ncrParams() {
      const {
        id,
        account,
        client_name: client,
        program_id,
        lead_id,
      } = this.client;
      const { user_id, role_id } = this.currentUser;
      return {
        client_account_id: id,
        id, // client_account_id
        account,
        program_id,
        lead_id,
        fullname: client, // full name
        user_id,
        role_id,
        type: "CLIENT",
        used_cards: JSON.stringify([]),
      };
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    this.addPreloader();
    const { data } = await ClientsOptionsServices.getMissingFiles({
      accountId: this.$route.params.idClient,
    });
    await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    await this.A_GET_ALL_SERVICES_COUNTER({
      client_account_id: this.$route.params.idClient,
      module_id: this.moduleId,
    });
    this.isResponsible();
    this.clientAccountId = this.$route.params.idClient;
    try {
      await Promise.all([
        this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
          id: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.$store.dispatch("DebtSolutionClients/A_COUNT_DISPUTE", {
          idaccountclient: this.$route.params.idClient,
        }),
        this.A_COUNT_PENDING_WP_CLIENT({
          client_account_id: this.$route.params.idClient,
        }),
        this.A_GET_COUNTERS_BY_CLIENT(this.$route.params.idClient),
        this.getUpdates(),
      ]);
      await this.countRequestNcrForUpdate();
      await this.infoLead();
      this.showViews = true;
      // await this.getCountFiles()
      // await this.getCounterDispute()
      this.removePreloader();
    } catch (error) {
      this.removePreloader();
      console.error(error);
    }
    this.validateImg = true;
    this.validateAccount = true;
    if (this.$route.matched[0].meta.general) {
      this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
      this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    }
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  async mounted() {
    // await this.A_COUNT_ANALYSIS_CR({idClient: this.$route.params.idClient, typeView: 'SETTLEMENTS'});
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({
      user_id: this.currentUser.user_id,
      client_account_id: this.$route.params.idClient,
    });
  },
  methods: {
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
    },
    ...mapActions({
      getHistorySms: "ParagonClientDashboard/getHistorySms",
      getHistoryEmail: "ParagonClientDashboard/getHistoryEmail",
      A_GET_ALL_SERVICES_COUNTER: "clients-store/A_GET_ALL_SERVICES_COUNTER",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
      A_COUNT_PENDING_WP_CLIENT: "clients-store/A_COUNT_PENDING_WP_CLIENT",
    }),
    async getUpdates() {
      const { data } = await ClientsOptionsServices.getCountUpdates({
        accountId: this.$route.params.idClient,
      });
      this.$store.dispatch("ReportStore/A_UPDATES_DISPUTE", data);
    },
    openModalHistoryStatement() {
      this.activeModalHistoryStatement = true;
    },
    closeModalHistoryStatement() {
      this.activeModalHistoryStatement = false;
    },

    closeModalHistoryEmail() {
      this.activeModalHistoryEmail = false;
    },
    closeModalHistorySms() {
      this.activeModalHistorySms = false;
    },
    async openModalHistoryEmail() {
      await this.getHistoryEmail(this.$route.params.idClient);
      this.activeModalHistoryEmail = true;
    },
    async openModalHistorySms() {
      await this.getHistorySms({ id: this.$route.params.idClient, type: 0 });
      this.activeModalHistorySms = true;
    },

    async refresh() {
      const data = await this.$store.dispatch(
        "DebtSolutionClients/A_GET_CLIENTS",
        {
          id: this.$route.params.idClient,
        }
      );
    },
    openCloseModalHistory() {
      this.modalHistoryOn = true;
    },
    closeModalHistory() {
      this.modalHistoryOn = false;
    },

    ...mapActions({
      A_GET_LEAD_EDIT: "CrmLeadStore/A_GET_LEAD_EDIT",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
    }),
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    async getCounterDispute() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.counterDispute(params);
        this.countdisputeclient = response.data.length;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async getCountFiles() {
      try {
        const params = {
          idaccountclient: this.$route.params.idClient,
        };
        const response = await ClientsOptionsServices.getFileCountClient(
          params
        );
        this.countfileclient = response.data[0].countfile;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId) ? "[1,2,5]" : "[1,2,3,5]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async openEditLead() {
      this.addPreloader();
      await this.getLeadEdit();
      if (this.S_LEAD_EDIT) {
        this.isAddUpdateUserSidebarActive = true;
      }
      this.removePreloader();
    },
    async infoLead() {
      await Promise.all([
        this.getLeadEdit(),
        this.getPrograms(),
        this.getStateLeads(),
        this.getStatusLeads(),
        this.getSourceLeads(),
        this.getSourceNames(),
        this.getStates(),
        this.getEeuuStates(),
        this.getCountries(),
        this.getOwners(),
      ]);
    },
    async getLeadEdit() {
      try {
        await this.A_GET_LEAD_EDIT({ id: this.client.lead_id });
      } catch (error) {
        console.log("Something went wrong getLeadEdit", error);
      }
    },
    async countRequestNcrForUpdate() {
      const { data } = await ClientsOptionsServices.countRequestNcrForUpdate({
        client_account_id: this.$route.params.idClient,
      });
      this.countNcrForUpdate = data.data;
    },

    /*  async sendFileCorrespondence(){
      try {
        const response = await this.showConfirmSwal();
        if(response.isConfirmed){
          const params = {
          idAccount:this.$route.params.idClient,
          status:1,
          moduleId:this.client.module,
          createdBy:1
        }
          const data =  await ClientsOptionsServices.sendReportPdfCorrespondece( params);
           if (data.status === 200) {
            this.showSuccessSwal();
          }
        }
      }catch (e) {
        this.showErrorSwal(e)
      } finally {
        this.removePreloader();
      }
    }, */

    openModalShare() {
      this.modalShare = true;
    },
    closeModalShare() {
      this.modalShare = false;
    },

    openModalProgramList() {
      this.modalProgramList = true;
    },
    closeModalProgramList() {
      this.modalProgramList = false;
    },

    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
      this.countRequestNcrForUpdate();
    },
    openModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = true;
    },
    closeModalCreateNcrRealtor() {
      this.modalCreateNcrRealtor = false;
    },

    openModalSendSms() {
      this.modalSendSms = true;
    },
    closeSendSms() {
      this.modalSendSms = false;
      this.modalSendEmail = false;
    },
    openModalSendEmail() {
      this.modalSendEmail = true;
    },
    openModalStatusReport() {
      this.modalStatusReport = true;
    },
    closeModalStatus() {
      this.modalStatusReport = false;
    },
    openModalTrackingHistoryClaims() {
      this.modalHistoryClaims = true;
    },
    closeModalTrackingHistoryClaims() {
      this.modalHistoryClaims = false;
    },
    openModalListCourtInfo() {
      this.modalListCourtInfo = true;
    },
    closeModalListCourtInfo() {
      this.modalListCourtInfo = false;
    },
  },
};
</script>

<style scoped>
.content-header .content-header-right button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.content-header .content-header-right .b-dropdown button {
  border-color: #00cfe8 !important;
  background-color: #00cfe8 !important;
}

.av-balance-border-blue {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #147ca7;
}

.av-balance-text-blue {
  color: white;
  background-color: #147ca7;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-blue {
  color: white;
  background-color: #0099c8;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.av-balance-border-red {
  border-radius: 7px;
  width: auto;
  padding: 3px;
  border: 1px solid #e30613;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}

.av-balance-text-red {
  color: white;
  background-color: #e30613;
  width: 100px;
  line-height: 13px;
  padding: 7px;
  margin: 0;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 7px;
}

.av-balance-content-red {
  color: white;
  background-color: #e83b4c;
  padding: 7px;
  margin: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
/* indicators flex */
.indicators {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
@media (max-width: 1000px) {
  /* hacer flex colum indicators */
  .indicators {
    flex-direction: column;
    align-items: flex-end;
  }
  .indicators div div div {
    margin-right: 0 !important;
  }
}
/* pantalla celular */
@media (max-width: 500px) {
  /* hacer flex colum indicators */
  .indicators {
    align-items: flex-start;
  }
}

.secundary-btn {
  background: #82868b !important;
  border-color: #82868b !important;
  background-color: #82868b !important;
}

.send-cr >>> .dropdown-toggle {
  padding: 0.6rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 3px;
}
.counter-style {
  width: 21px;
  height: 21px;
  background-color: red;
  padding: 3px;
  border-radius: 50%;
  bottom: 20px;
}
</style>
